import React, {Component} from 'react';
import {
    Link
} from "react-router-dom";

import Menu from "../Menu";
import PageHeader from "../PageHeader"
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";

export class Events extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            events:[],
        };
    }

    componentDidMount() {
        let urlAPI2 = GlobalVariable.staffBack() + '/getEvents';
        const data2 = {};
        axios({
            url: urlAPI2,
            headers: {'Content-Type': 'application/json'},
            method: "GET",
            data: data2,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({
                    events: result.data,
                })
            }).catch(error => {

            this.setState({
                auth: false,
                isLoad: true,
            })
            // window.location.href = '/' ;
        })
    }


    render() {
        return (
            <div className='main news events '>
                <div className='content mx-w-content'>

                    <PageHeader
                        pageTitle='События'
                        backBtnUrl='/'/>
                        <div className='pt-header'></div>

                    <div className='ml-3 mr-3'>
                        <Link to={'/event/1' }>
                            <div
                                className='chio-card-subheader pl-3 pr-3 pt-2 d-flex justify-content-between'>
                                <span className='text-white txt-middle'>13.09.2024</span>
                                <span className='text-white txt-middle'></span>
                            </div>
                            <div className='chio-card mb-2'>
                                <img className='card-img-wh' alt=''
                                     src='/img/events/event11.jpg'/>
                                <div className='p-3 txt-middle bold'>
                                    С Днем Парикмахера! Промокоды на скидки.
                                </div>
                            </div>
                        </Link>
                        <Link to={'/event/2' }>
                            <div
                                className='chio-card-subheader pl-3 pr-3 pt-2 d-flex justify-content-between'>
                                <span className='text-white txt-middle'>10.09.2024</span>
                                <span className='text-white txt-middle'></span>
                            </div>
                            <div className='chio-card mb-2'>
                                <img className='card-img-wh' alt=''
                                     src='/img/events/img-event1.jpg'/>
                                <div className='p-3 txt-middle bold'>
                                    Как общаться с посетителем, чтобы он стал твоим
                                    постоянным клиентом?

                                </div>
                            </div>
                        </Link>
                        <Link to={'/event/2' }>
                            <div
                                className='chio-card-subheader pl-3 pr-3 pt-2 d-flex justify-content-between'>
                                <span className='text-white txt-middle'>07.09.2024</span>
                                <span className='text-white txt-middle'></span>
                            </div>
                            <div className='chio-card mb-2'>
                                <img className='card-img-wh' alt=''
                                     src='/img/events/img-event1.jpg'/>
                                <div className='p-3 txt-middle bold'>
                                    Как подобрать стрижку под тип внешности клиента?
                                </div>
                            </div>
                        </Link>


                    </div>
                </div>
                <Menu></Menu>
            </div>
        )
    }
}

export default Events;