import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../GlobalVariable";
import axios from "axios";
import ReactDOM from 'react-dom';
import Menu from "./Menu";
import Start from "./Start/Start";
import Home from "./Home/Home";

export class Index extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            staff: '',
            service: [],
            dateTime: '',
            activeBlock: '',
            openChangePoint: false,
            auth: 1,
        };
    }

    componentDidMount() {
        const data = {

        };
        const data2 = {};
        axios({
            url: GlobalVariable.host() + '/mobile/profile/check-auth',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "GET",
            data: data2,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({

                    isLoad: true,
                    auth: 2,
                    info: 'Двойной Успех',
                })
            }).catch(error => {
            this.setState({auth: 3, isLoad: true})
            // window.location.href = '/' + this.props.widgetId + '/profile';
        })
    }

    render() {
        if (this.state.auth === 1) {
            return (
                <div className='main analitycs'>
                    <div className='content mx-w-content'>
                        {/*<div className={'global start'}>*/}
                        {/*    <div className={'top-block'}>*/}
                        {/*        <div className={'rectangle'}>*/}
                        {/*            <div>*/}
                        {/*                <span>147</span> <br/> городов*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className={'rectangle'}>*/}
                        {/*            <div>*/}
                        {/*                постригли*/}
                        {/*                <span> 3,8 млн </span>*/}
                        {/*                любимых*/}
                        {/*                клиентов*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className={'rectangle'}>*/}
                        {/*            Россия*/}
                        {/*            Беларуссия*/}
                        {/*            Казахстан*/}
                        {/*            Грузия*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className={'title'}>*/}
                        {/*        Добро пожаловать!*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <Menu></Menu>
                </div>
            )

        }

        // главная страница
        if (this.state.auth === 2) {
            return (
                <Home></Home>
            )
        }

        //
        if (this.state.auth === 3) {
            return (
                <Start></Start>
            )

        }
    }
}

export default Index;