import React, {Component} from 'react';
import axios from "axios";
import GlobalVariable from "../../GlobalVariable";
import PageHeader from "../PageHeader";
import Preloader from "../Preloader";
import Modal from "./Modal";


export class Home extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            phone: '',
            code: '',
            step: 'phone', // phone | load | code
            btnPhone: false,
            btnCode: false,
            params: [],
            timer: 45,

            showPrivacyPolicy: false,
            showUserAgreement: false
        };
        this.timer = this.timer.bind(this);
        this.changePhone = this.changePhone.bind(this);
        this.changeCode = this.changeCode.bind(this);
        this.nextStepCode = this.nextStepCode.bind(this);
        this.auth = this.auth.bind(this);
        this.handlePrivacyChange = this.handlePrivacyChange.bind(this);
        this.handleAgreementChange = this.handleAgreementChange.bind(this);
    }

    //Ввод номера телефона
    changePhone(event) {
        let val = event.target.value;
        val = val.replace(/[^+\d]/g, '');
        let num = `${val.substring(0, 1)} ${val.substring(1, 4)} ${val.substring(4, 7)} ${val.substring(7, val.length)}`;
        num = num.trim();
        if (num.length <= 14) {
            this.setState({phone: num})
        }
        if (num.length == 14) {
            this.setState({btnPhone: true})
        } else {
            this.setState({btnPhone: false})
        }
    }

    //Ввод кода
    changeCode(event) {
        let code = event.target.value.replace(/[^0-9]/g, "")
        if (code.length < 5) {
            this.setState({code: code});
        }
        if (code.length == 4) {
            this.setState({btnCode: true});
        } else {
            this.setState({btnCode: false});
        }
    }

    //Проверка номера телефона в базе, отправка кода, вкл отсчет таймера
    nextStepCode() {
        this.setState({
            step: 'load'
        });
        const data = {
            phone: this.state.phone.replace(/[^0-9]/g, "")
        };
        axios({
            url: GlobalVariable.host() + '/open/widget/security/check/phone/staff',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data
        })
            .then(result => { // then print response status
                if (result.data.status === 'noPhone') {
                    this.setState({auth: false})
                    window.location.href = '/master-define'
                } else if (result.data.status === 'bad') {
                    this.setState({auth: false})
                } else {
                    this.setState({step: 'code'});
                    if (this.state.timer > 0) {
                        setInterval(this.timer, 1000);
                    }
                }
            }).catch(error => {
            this.setState({auth: false, isLoad: true})
        })
    }

    //Проверка кода
    auth() {
        const data = {
            code: this.state.code,
            phone: this.state.phone.replace(/[^0-9]/g, "")
        };
        axios({
            url: GlobalVariable.host() + '/open/mobile/security/check/staff/code',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data
        })
            .then(result => { // then print response status
                    if (result.data.status === 'bad') {
                        this.setState({auth: false})
                    } else {
                        this.setState({auth: true})
                        if (result.data.status === 'bad') { //если ответ плохой, то
                            this.setState({
                                auth: false,
                                step: 'phone'
                            })
                            window.location.href = '/master-define';
                        }
                        if (this.state.params.url) {
                            window.location.href = this.state.params.url;
                        } else {
                            window.location.href = '/home';
                        }
                    }
                }
            )
            .catch(error => {
                this.setState({auth: false, isLoad: true})
            })
    }

    //Кнопка, запускающая звонок мастеру
    renderButtonPhone() {
        if (this.state.phone.length == 14) {
            return (
                <div onClick={() => this.nextStepCode()}>
                    <div className='chio-card bg-red text-white pt-3 pb-3 pl-5 pr-5 d-table m-auto'>
                        Продолжить
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{backgroundColor: '#dbdbdb'}} className='chio-card pt-3 pb-3 pl-5 pr-5 d-table m-auto'>
                    Продолжить
                </div>
            )
        }
    }

    //Запуск таймера
    timer() {
        if (this.state.timer > 0) {
            this.setState({timer: this.state.timer - 1})
        }
    }

    handlePrivacyChange() {
        this.setState({showPrivacyPolicy: !this.state.showPrivacyPolicy})
    }

    handleAgreementChange() {
        this.setState({showUserAgreement: !this.state.showUserAgreement})
    }

    //Что это?
    componentDidMount() {
        let searchParams = window.location.search.substr(1).split('&');
        let queryParams = {};

        for (let param of searchParams) {
            let [key, value] = param.split('=');
            queryParams[key] = decodeURIComponent(value || "");
        }
        this.setState({params: queryParams})
    }

    //Кнопка, проверяющая код подтверждения
    renderButtonCode() {
        if (this.state.code.length == 4) {
            return (
                <div onClick={() => this.auth()}>
                    <div className='chio-card bg-red text-white pt-3 pb-3 pl-5 pr-5 d-table m-auto'>
                        Продолжить
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{backgroundColor: '#dbdbdb'}} className='chio-card pt-3 pb-3 pl-5 pr-5 d-table m-auto'>
                    Продолжить
                </div>
            )
        }
    }

    //Кнопка, предлагающая отправить код ещё раз
    renderRepeatCode() {
        if (this.state.timer == 0) {
            return (
                <p onClick={() => this.nextStepCode()}> Отправить код еще раз</p>
            )
        } else {
            return (
                <p>Отправить код еще раз можно будет, <br/> через {this.state.timer} секунд</p>
            )
        }
    }

    render() {

        if (this.state.step === 'phone') {
            return (
                <div>
                    {/*Модальные окна*/}
                    {this.state.showUserAgreement &&
                    <Modal
                        title={'Пользовательское соглашение'}
                        content={'Текст 1'}
                        onClick={this.handleAgreementChange}/>}
                    {this.state.showPrivacyPolicy &&
                    <Modal
                        title={'Политика конфиденциальности'}
                        content={'Текст 2'}
                        onClick={this.handlePrivacyChange}/>}


                    <div className='main'>
                        <div className='mx-w-content'>

                            <PageHeader
                                pageTitle='Вход'
                                backBtnUrl='/start'/>
                            <div className='pt-header'></div>
                        </div>
                    </div>
                    <div className={'login'}>

                        <div className='mx-w-content'>

                            <div className='pl-3 pr-3'>
                                <div className={'d-flex mt-3 mb-3'}>
                                    <div>
                                        <img src={'/img/logo.png'}/>
                                    </div>
                                    <div className={'pl-3'}>
                                        <div className={'title1'}>Введите номер телефона</div>
                                        <div className='title2 dark-textc'> Чтобы войти в кабинет мастера Чио&nbsp;Чио
                                        </div>
                                    </div>
                                </div>
                                <div className={'form mt-3 mb-3'}>
                                    <div className={'input d-flex'}>
                                        <div className={'flag'}></div>
                                        {/*<div className={'img'}><img src="/img/phone-false.png"/></div>*/}
                                        {/*<div style={{position:"absolute",  paddingLeft:'50px'}} className={''}>+7</div>*/}
                                        <input onChange={this.changePhone} style={{paddingLeft: '73px'}}
                                               value={this.state.phone}
                                               placeholder={'7 (xxx) xxx-xx-xx'}
                                               className={'input-phone '}/>
                                    </div>
                                </div>
                                <div className={'checkbox mt-3 mb-3'}>
                                    <div className='text-center'>
                                        Нажимая «Продолжить», Вы принимаете <u onClick={this.handleAgreementChange}>
                                        Пользовательское соглашение</u> и <u
                                        onClick={this.handlePrivacyChange}> Политику
                                        конфиденциальности</u>
                                    </div>
                                </div>
                                {this.renderButtonPhone()}
                            </div>
                            {/*<Menu></Menu>*/}
                        </div>
                    </div>
                </div>
            )
        } else if (this.state.step === 'code') {
            return (
                <div>
                    {/*Модальные окна*/}
                    {this.state.showUserAgreement &&
                    <Modal
                        title={'Пользовательское соглашение'}
                        content={'Текст 1'}
                        onClick={this.handleAgreementChange}/>}
                    {this.state.showPrivacyPolicy &&
                    <Modal
                        title={'Политика конфиденциальности'}
                        content={'Текст 2'}
                        onClick={this.handlePrivacyChange}/>}

                    <div className='main'>
                        <div className='mx-w-content'>

                            <PageHeader
                                pageTitle='Вход'
                                backBtnUrl='/start'/>
                            <div className='pt-header'></div>
                        </div>
                    </div>
                    <div className={'login'}>

                        <div className='mx-w-content'>
                            <div className='pl-3 pr-3'>
                                <div className={'d-flex mt-3 mb-3'}>
                                    <div>
                                        <img src={'/img/logo.png'}/>
                                    </div>
                                    <div className={'pl-3'}>
                                        <div className={'title1'}>Введите код</div>
                                        <div className='title2 dark-textc'> Чтобы войти в кабинет мастера Чио&nbsp;Чио
                                        </div>
                                    </div>
                                </div>
                                <div className={'info mt-3 mb-3'}>
                                    <p>Звонок поступит на номер +{this.state.phone}</p>
                                </div>
                                <div className={'form mt-3 mb-3'}>
                                    <div className={'input d-flex'}>
                                        {/*<div className={'img'}><img src="/img/phone-false.png"/></div>*/}
                                        {/*<div style={{position:"absolute",  paddingLeft:'50px'}} className={''}>+7</div>*/}
                                        <input value={this.state.code} placeholder={'x x x x'}
                                               onChange={this.changeCode}
                                               className={'input-code w-100'}/>
                                    </div>
                                </div>
                                <div className={'info mt-3 mb-3'}>
                                    <p>Нужно <b>ответить</b> на входящий звонок, робот <b>продиктует</b> код
                                        подтверждения</p>
                                </div>
                                {this.renderButtonCode()}
                                <div className={'info mt-3 mb-3'} style={{color: '#9E9E9E'}}>
                                    {this.renderRepeatCode()}
                                </div>
                            </div>
                            {/*<Menu></Menu>*/}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <>
                    {/*Модальные окна*/}
                    {this.state.showUserAgreement &&
                    <Modal
                        title={'Пользовательское соглашение'}
                        content={'Текст 1'}
                        onClick={this.handleAgreementChange}/>}
                    {this.state.showPrivacyPolicy &&
                    <Modal
                        title={'Политика конфиденциальности'}
                        content={'Текст 2'}
                        onClick={this.handlePrivacyChange}/>}

                    <Preloader></Preloader>
                </>
            )
        }

    }
}

export default Home;

