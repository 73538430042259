import './ChoosePeriod.css'

import React, {Component} from 'react';
import {
    XYPlot,
    RadialChart,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries,
    LabelSeries,
    DiscreteColorLegend,
} from 'react-vis';

import Menu from "../Menu";
import PageHeader from "../PageHeader";

import '../../../node_modules/react-vis/dist/style.css';
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";

const visColors = {
    dark: '#0d263f',
    light: '#cdcdcd',
    bright: '#e40136'
}

//Динамика выручки
const data1 = [
    {x: new Date('09/01/24').toLocaleDateString(), y: 200000, label: 200000 + 'p'},
    {x: new Date('09/02/24').toLocaleDateString(), y: 70000, label: 70000 + 'p'},
    {x: new Date('09/03/24').toLocaleDateString(), y: 20000, label: 20000 + 'p'},
    {x: new Date('09/04/24').toLocaleDateString(), y: 89000, label: 89000 + 'p'},
    {x: new Date('09/05/24').toLocaleDateString(), y: 89000, label: 89000 + 'p'},
    {x: new Date('09/06/24').toLocaleDateString(), y: 0, label: 0 + 'p'},
    {x: new Date('09/07/24').toLocaleDateString(), y: 1000, label: 1000 + 'p'},
];

//Доля МОИХ постоянных клиентов
const data2 = [
    {angle: 27, label: 27 + '%'},
    {angle: 63, label: 63 + '%'}
];

//Доля постоянных клиентов САЛОНА
const data3 = [
    {angle: 27, label: 27 + '%'},
    {angle: 63, label: 63 + '%'}
];

//Доля постоянных клиентов в ГОРОДЕ
const data4 = [
    {angle: 27, label: 27 + '%'},
    {angle: 63, label: 63 + '%'}
];

//Доля незарегистрированных клиентов
const data51 = [ //ЗАРЕГИСТРИРОВАННЫЕ
    {x: 'мои', y: 70, label: 70 + '%'}, //МОИ
    // {x: 'в салоне', y: 30, label: 30 + '%'}, //САЛОН
    // {x: 'в городе', y: 50, label: 50 + '%'} //ГОРОд
]
const data52 = [ //НЕЗАРЕГИСТРИРОВАННЫЕ
    {x: 'мои', y: 30, label: 30 + '%'}, //МОИ
    // {x: 'в салоне', y: 70, label: 70 + '%'}, //САЛОН
    // {x: 'в городе', y: 50, label: 50 + '%'} //ГОРОд
]
const data53 = ['мои'] //Подписи оси X
// const data53 = ['мои', 'в салоне', 'в городе'] //Подписи оси X


function ChoosePeriod() {
    return (
        <div className='d-flex choose-period mb-4'>
            <div className='chio-card pt-1 pb-1 pr-2 pl-2 mr-1 active'>1&nbsp;неделя</div>
            <div className='chio-card pt-1 pb-1 pr-2 pl-2 mr-1'>1&nbsp;месяц</div>
            <div className='chio-card pt-1 pb-1 pr-2 pl-2 mr-1'>3&nbsp;месяца</div>
            <div className='chio-card pt-1 pb-1 pr-2 pl-2 mr-1'>1&nbsp;год</div>
            <div className='chio-card pt-1 pb-1 pr-2 pl-2 mr-1'>2&nbsp;года</div>
        </div>
    )
}

export class Analytics extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            data1: [],
            data2: [],
            data51: [],
            data52: [],
        };
    }

    componentDidMount() {
        let urlAPI = GlobalVariable.staffBack() + '/mobile/analytics/staff';
        const data = {};
        axios({
            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            method: "POST",
            data: data,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status

                this.setState({
                    data1: result.data.info,
                    data2: result.data.data2,
                    data51: result.data.data51,
                    data52: result.data.data52,
                    isLoad: true,
                    auth: true
                })
            }).catch(error => {

            this.setState({
                auth: false,
                isLoad: true,
            })
            // window.location.href = '/' ;
        })
    }


    render() {
        console.log(this.state.data52)
        return (
            <div className='main analytic'>
                <div className='mx-w-content'>

                    <PageHeader
                        pageTitle='Аналитика'
                        backBtnUrl='/'/>
                    <div className='pt-header'></div>

                    {/*<div className='pl-3 pr-3'>*/}
                    {/*    <h2 className='mb-2 mt-0 txt-large'>Основная информация</h2>*/}
                    {/*    <table className='table'>*/}
                    {/*        <tbody>*/}
                    {/*        <tr>*/}
                    {/*            <td className='pl-0 border-top-0 txt-middle font-weight-normal'>Имя</td>*/}
                    {/*            <td className='border-top-0 txt-middle bold'>Игорь</td>*/}
                    {/*        </tr>*/}
                    {/*        <tr>*/}
                    {/*            <td className='pl-0 txt-middle font-weight-normal'>Стаж</td>*/}
                    {/*            <td className='txt-middle bold'>18 месяцев</td>*/}
                    {/*        </tr>*/}
                    {/*        </tbody>*/}
                    {/*    </table>*/}
                    {/*</div>*/}


                    <div className='ml-3 mr-3 mt-4 mb-4'>
                        {/*<div className='d-flex'>*/}
                        {/*    <div className='w-50 mb-2 mr-1'>*/}
                        {/*        <div*/}
                        {/*            className='chio-card-subheader pl-2 pr-2 pt-3 text-center txt-middle'>*/}
                        {/*            Моя оценка по отзывам*/}
                        {/*        </div>*/}
                        {/*        <div className='chio-card p-3 text-center'>*/}
                        {/*            <span className='txt-xxlarge' style={{color: 'green'}}>4,7</span>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className='w-50 mb-2 ml-1'>*/}
                        {/*        <div*/}
                        {/*            className='chio-card-subheader pl-2 pr-2 pt-3 text-center txt-middle'>*/}
                        {/*            Мой рейтинг*/}
                        {/*        </div>*/}
                        {/*        <div className='chio-card p-3 text-center'>*/}
                        {/*            <span className='txt-xxlarge dark-textc'>14</span>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className='d-flex'>*/}
                        {/*    <div className='w-50 mb-2 mr-1'>*/}
                        {/*        <div*/}
                        {/*            className='chio-card-subheader pl-2 pr-2 pt-3 text-center txt-middle'>*/}
                        {/*            Рейтинг салона по городу*/}
                        {/*        </div>*/}
                        {/*        <div className='chio-card p-3 text-center'>*/}
                        {/*            <span className='txt-xxlarge dark-textc'>3</span>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className='w-50 mb-2 ml-1'>*/}
                        {/*        <div*/}
                        {/*            className='chio-card-subheader pl-2 pr-2 pt-3 text-center txt-middle'>*/}
                        {/*            Рейтинг салона по сети*/}
                        {/*        </div>*/}
                        {/*        <div className='chio-card p-3 text-center'>*/}
                        {/*            <span className='txt-xxlarge dark-textc'>56</span>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className='bg-shadow'>
                    {/*<div className='pl-3 pr-3 pt-4 pb-4 mx-w-content'>*/}
                    {/*    <h2 className='mb-3 mt-0 txt-large'>Показать аналитику для</h2>*/}

                    {/*    <h2 className='mb-1 txt-middle bold'>Cалон</h2>*/}
                    {/*    <select className="form-select txt-middle" aria-label="Default select example">*/}
                    {/*        <option className="txt-middle" value="1" selected>Йошкар-Ола, Кирова 8</option>*/}
                    {/*        <option className="txt-middle" value="2">Йошкар-Ола, Чехова 15</option>*/}
                    {/*        <option className="txt-middle" value="3">Йошкар-Ола, Гагарина 132</option>*/}
                    {/*    </select>*/}

                    {/*    <h2 className='mt-4 mb-1 txt-middle bold'>Данные за период c</h2>*/}
                    {/*    <input className='form-control' type='date'/>*/}
                    {/*    <h2 className='mt-1 mb-1 txt-middle bold'>по</h2>*/}
                    {/*    <input className='form-control' type='date'/>*/}
                    {/*</div>*/}
                </div>

                <div className='content mx-w-content'>
                    <div className='pl-3 mt-4'>

                        <ChoosePeriod/>

                        <h2 className='txt-large mb-2'>Динамика выручки</h2>
                        <div style={{overflowY: 'scroll'}}>
                            <XYPlot xType="ordinal" height={200} width={this.state.data1.length * 85}
                                    color={visColors.dark}>
                                <VerticalGridLines/>
                                <HorizontalGridLines/>
                                <XAxis/>
                                <YAxis
                                    left={16}/>
                                <VerticalBarSeries data={this.state.data1}/>
                                <LabelSeries
                                    data={this.state.data1}
                                    labelAnchorX="middle"
                                    labelAnchorY="text-before-edge"
                                    style={{
                                        fill: visColors.bright,
                                        fontSize: 14,
                                        fontWeight: 'bold'
                                    }}
                                />
                            </XYPlot>
                        </div>
                        <DiscreteColorLegend
                            colors={[visColors.dark]}
                            items={['Выручка за день в рублях']}
                            orientation="horizontal"/>
                    </div>

                    <div className='ml-3 mr-3'>
                        <h2 className='txt-large mb-2 mt-3'>Доля моих постоянных клиентов</h2>
                        <div style={{maxWidth: '200px', margin: 'auto'}}>
                            <RadialChart
                                height={200}
                                width={200}
                                colorType="category"
                                colorRange={[visColors.dark, visColors.light]}
                                data={this.state.data2}
                                labelsRadiusMultiplier={0.8}
                                labelsStyle={{
                                    fill: visColors.bright,
                                    fontSize: 12
                                }}
                                showLabels/>
                            <DiscreteColorLegend
                                colors={[visColors.dark, visColors.light]}
                                items={['Постоянные', 'Новые']}
                                orientation="horizontal"/>
                        </div>

                        {/*<h2 className='txt-large mb-2 mt-4'>Доля постоянных клиентов в салоне</h2>*/}
                        {/*<div style={{maxWidth: '200px', margin: 'auto'}}>*/}
                        {/*    <RadialChart*/}
                        {/*        height={200}*/}
                        {/*        width={200}*/}
                        {/*        colorType="category"*/}
                        {/*        colorRange={[visColors.dark, visColors.light]}*/}
                        {/*        data={data3}*/}
                        {/*        labelsRadiusMultiplier={0.8}*/}
                        {/*        labelsStyle={{*/}
                        {/*            fill: visColors.bright,*/}
                        {/*            fontSize: 14*/}
                        {/*        }}*/}
                        {/*        showLabels/>*/}
                        {/*    <DiscreteColorLegend*/}
                        {/*        colors={[visColors.dark, visColors.light]}*/}
                        {/*        items={['Постоянные', 'Новые']}*/}
                        {/*        orientation="horizontal"/>*/}
                        {/*</div>*/}

                        {/*<h2 className='txt-large mb-2 mt-xxl-4'>Доля постоянных клиентов в городе</h2>*/}
                        {/*<div style={{maxWidth: '200px', margin: 'auto'}}>*/}
                        {/*    <RadialChart*/}
                        {/*        height={200}*/}
                        {/*        width={200}*/}
                        {/*        colorType="category"*/}
                        {/*        colorRange={[visColors.dark, visColors.light]}*/}
                        {/*        data={data4}*/}
                        {/*        labelsRadiusMultiplier={0.8}*/}
                        {/*        labelsStyle={{*/}
                        {/*            fill: visColors.bright,*/}
                        {/*            fontSize: 14*/}
                        {/*        }}*/}
                        {/*        showLabels/>*/}
                        {/*    <DiscreteColorLegend*/}
                        {/*        colors={[visColors.dark, visColors.light]}*/}
                        {/*        items={['Постоянные', 'Новые']}*/}
                        {/*        orientation="horizontal"/>*/}
                        {/*</div>*/}
                    </div>

                    <div className='pl-3 pr-3 mt-4'>
                        <h2 className='txt-large mb-2 mt-3'>Доля незарегистрированных клиентов</h2>
                        <div className='m-auto d-table'>
                            <XYPlot xType="ordinal" width={250} height={300}
                                    stackBy="y"
                                    xDomain={data53}
                                    yDomain={[0, 100]}>
                                <VerticalGridLines/>
                                <HorizontalGridLines/>
                                <XAxis/>
                                <YAxis/>
                                <VerticalBarSeries
                                    cluster="stack 1"
                                    data={this.state.data51}
                                    color={visColors.dark}
                                    style={{}}/>
                                <VerticalBarSeries
                                    cluster="stack 1"
                                    data={this.state.data52}
                                    color={visColors.light}
                                    style={{}}/>
                                <LabelSeries
                                    data={this.state.data51}
                                    labelAnchorX="middle"
                                    labelAnchorY="text-before-edge"
                                    style={{
                                        fill: visColors.bright,
                                        fontSize: 12
                                    }}/>
                                <LabelSeries
                                    data={this.state.data52}
                                    labelAnchorX="middle"
                                    labelAnchorY="text-before-edge"
                                    style={{
                                        fill: visColors.bright,
                                        fontSize: 12
                                    }}/>
                            </XYPlot>
                            <DiscreteColorLegend
                                colors={[visColors.dark, visColors.light]}
                                items={['Зарегистрированные', 'Незарегистрированные']}
                                orientation="horizontal"/>
                        </div>
                    </div>
                </div>
                <Menu></Menu>

            </div>
        )
    }
}

export default Analytics;